@import './variables.scss';

html,
body {
    scroll-behavior: auto;
    margin: 0px;
    padding: 0px;
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    font-family: 'FordF-1';
}

@font-face {
    font-family: 'FordF-1';
    src: url('./assets/fonts/FordF-1-Light.otf') format("woff");
    font-weight: 300;
}

@font-face {
    font-family: 'FordF-1';
    src: url('./assets/fonts/FordF-1-Regular.otf') format("woff");
    font-weight: 400;
}

@font-face {
    font-family: 'FordF-1';
    src: url('./assets/fonts/FordF-1-Medium.otf') format("woff");
    font-weight: 700;
}

@font-face {
    font-family: 'FordF-1';
    src: url('./assets/fonts/FordF-1-Semibold.otf') format("woff");
    font-weight: 800;
    font-weight: bold;
}

.scrollbar {

    &::-webkit-scrollbar-thumb {
        background-color: $ford-accent-blue;
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
    }
      
    &::-webkit-scrollbar {
        width: 16px;
    }
}

.scrollbar-hidden {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}

.screen {
    @extend .scrollbar-hidden;

    width: 100vw;
    height: 100dvh;
    overflow: scroll;
    position: absolute;
    box-sizing: border-box;

    // gpu acceleration
    // perspective: 1000px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

.page-padding {
    display: flex;
    flex-direction: column;
    padding: calc(44px + 95px) 32px;

    // small screen
    @media (max-width: $small-screen)  {
        padding: calc(44px + 95px) 16px 64px 16px;
    }
}

.h1 {
    font-family: 'FordF-1';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    color: $ford-blue;
    padding: 64px 0px;

    // small screen
    @media (max-width: $small-screen)  {
        font-size: 40px;
        padding: 12px 0px 32px;
    }
}

.reset-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

button.btn {
    @extend .reset-button;
    background-color: $ford-accent-blue;
    padding: 4px 32px 4px 32px;
    font-family: 'FordF-1';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: white;
    border-radius: 17px;
    border: none;
    align-self: flex-start;
    margin-top: auto;
    cursor: pointer;
    
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: $ford-blue;
        color: white;
        // outline: 1px solid $ford-blue;
    }

    &.outline {
        background-color: transparent;
        border: 1px solid white;

        &:hover {
            background-color: $ford-accent-blue;
            color: white;
            border: 1px solid transparent;
        }
    }

    &.narrow {
        padding: 4px 16px 4px 16px;
    }
}

.invalid-feedback {
    display: none;
    margin-top: 4px;
    color: $color-error;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 18px;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
}


// Google Places Autocomplete Styles
.pac-icon {
    display: none;
}

.pac-item, .pac-item-query {
    font-family: 'FordF-1';
    color: $ford-accent-blue !important;
    padding-left: 10px;
}

.pac-container {
    border-radius: 12px;
    border: solid 1px $ford-accent-blue;
}

.pac-logo:after {
    display: none;
}

body input[data-com-onepassword-filled],
body input[data-com-onepassword-filled]:focus{
  background-clip: text !important;
}

// remove autofill background color in chrome
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 60px #ffffff00 inset !important;
    background-color: transparent !important;
    background-clip: content-box !important;
}

.disabled {
    pointer-events: none;
}