$ford-blue: #00095B;
$ford-twilight-blue: #00142E;
$ford-accent-blue: #066FEF;
$mache-grey: #B8B8B8;
$light-grey: #F0F0F0;
$color-error-outline: #C53E26;
$color-error: #FF5252;

$header-height: 53px;
$small-screen: 480px;
$tablet-screen: 786px;

$z-index-error-notification: 1200;
$z-index-lang-selector-top: 1100;
$z-index-header: 1000;
$z-index-lang-selector: 900;
$z-index-stepper: 700;
